<template>
  <div>
    <nav class="nav">
      <ul>
        <li v-if="!auth"><router-link to="login" :style="'color:' + ($route.fullPath === '/admin/login' ? 'red' : 'inherit')">ACCESO A TU CUENTA</router-link></li>
        <li v-if="!auth"><router-link to="signin" :style="'color:' + ($route.fullPath === '/admin/signin' ? 'red' : 'inherit')">REGISTRAR CUENTA</router-link></li>
        <li v-if="!auth"><router-link to="reset" :style="'color:' + ($route.fullPath === '/admin/reset' ? 'red' : 'inherit')">RECUPERAR PASSWORD</router-link></li>
        <li v-if="auth"><router-link to="config"></router-link></li>
      </ul>
    </nav>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Edit',
  computed: {
    ...mapGetters(['auth']),
  },
  mounted() {
    if (this.auth) {
      this.$router.replace('/admin/config').catch(() => {});
    }
  },
};
</script>
